import { type StatusBadgeFlavor } from '@cca-common/core';

export type RawHeaderData = {
  badge?: string | null;
  flavor?: StatusBadgeFlavor | null;
};

export type TranslateHeaderData = RawHeaderData & {
  translationKey: string;
  translocoParams?: Record<string, unknown>;
  translocoScope: string;
};

export type StaticHeaderData = RawHeaderData & {
  title: string;
};

export function isTranslateHeaderData(x: HeaderData): x is TranslateHeaderData {
  return !!(x && 'translationKey' in x && 'translocoScope' in x);
}

export type HeaderData = TranslateHeaderData | StaticHeaderData | null;
